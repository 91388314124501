/**
 * Created with JetBrains PhpStorm.
 * User: gschulz
 * Date: 12.08.13
 * Time: 13:39
 */

let MediaUploadTool = (function () {

    let _private = {};

    _private.instance = this;

    _private.options = {};

    _private.media = null;

    this.init = function () {
        _private.media = new Media();
        _private.initMediaSearch();
        let body = $('body');
        body.unbind('click.showMediaEditForm').on('click.showMediaEditForm', 'a.media-item-edit', function (event) {
                event.preventDefault();
                let button    = $(event.currentTarget);
                let mediaItem = button.parents('div.media-edit-item');
                let options   = {
                    url:      button.attr('href'),
                    media_id: mediaItem.find('input[name*=object_id]').pVal(),
                    item:     mediaItem
                };
                _private.extendOptions(button, options);
                _private.instance.showMediaEditForm(options, undefined, undefined, function (form, data, status) {
                    if (status === 'delete_ok') {
                        let mediaEditList = mediaItem.parent('div.media-edit-list');
                        new FormObserver(form).removeElement(mediaItem.find('input[name*=object_id]'));
                        mediaItem.remove();
                        _private.reorderList(mediaEditList);
                    }
                });
            }
        );
        body.unbind('click.removeMediaItem').on('click.removeMediaItem', 'a.media-item-remove', function (event) {
            event.preventDefault();
            let list = $(event.target).parents('div.media-edit-list');
            let form = $(event.target).parents('form');
            new FormObserver(form)
                .removeElements($(event.target).parents('div.media-edit-item').find('input'));
            $(event.target).parents('div.media-edit-item').remove();
            _private.reorderList(list);
            if (list.hasClass('relation-one')) {
                list.parent('fieldset').find('input[type=text]').attr('disabled', false);
            }
            form.trigger('mediaUploadToolChange', [{data: {object_id: 0, title: 0}}]);
        });
    };

    _private.extendOptions = function (button, options) {
        options.url  = button.attr('href');
        let editList = button.parents('div.media-edit-list');
        if (!editList.length) {
            return;
        }
        _private.extendOptionsByFieldset(editList, options);

    };

    _private.extendOptionsByFieldset = function (editList, options) {
        options.media_types = [];
        options.mime_types  = [];
        if (!_.isUndefined(editList.data('media-types')) && editList.data('media-types') !== '') {
            options.media_types = editList.data('media-types').split(',');
        }
        if (!_.isUndefined(editList.data('mime-types')) && editList.data('mime-types') !== '') {
            options.mime_types = editList.data('mime-types').split(',');
        }
    };

    _private.initMediaSearch = function () {
        let autocompleteInput = $('input[name=search_media_item]');
        let fieldSet          = autocompleteInput.parents('fieldset.media-edit');
        let editList          = fieldSet.find('div.media-edit-list');
        let multiple          = editList.data('relation') === 'many';
        autocompleteInput.efbAutocomplete({
            minLength: 2,
            source:    function (request, response) {
                request.element = this.element;
                $.ajax({
                    url:      urlHelper.getSimpleUrl('media', 'search'),
                    dataType: "json",
                    data:     _private.getAutocompleteParams(request),
                    success:  function (data) {
                        response($.map(data, function (media) {
                            return {
                                label: media.title,
                                id:    media.object_id,
                                value: media.title,
                                media: media
                            };
                        }));
                    }
                });
            },
            select:    function (event, ui) {
                autocompleteInput.val('');
                let target = editList.find('div.media-edit-item.media-0');
                if(!multiple){
                    editList.find('div.media-edit-item').not(target).remove();
                }
                _private.updateMediaItem(ui.item.media, editList.find('div.media-edit-item.media-0'));
                $(this).parents('form').trigger('mediaUploadToolChange', [{data: ui.item.media}]);

            },
            close:     function () {
            }
        });
        autocompleteInput.unbind('blur.removeValue').on('blur.removeValue', () => {
            autocompleteInput.val('');
        })
    };

    _private.getAutocompleteParams = function (request) {
        let fieldSet = $(request.element).parents('fieldset.media-edit');
        let params   = {
            limit:  10,
            light:  1,
            filter: {
                title:     {like: '%' + request.term + '%'},
                object_id: {
                    nin: _private.getExistingMediaIds(fieldSet)
                }
            },
            order:  {title: 'ASC'}
        };
        let options  = {};
        _private.extendOptionsByFieldset(fieldSet.find('div.media-edit-list'), options);
        if (options.media_types.length) {
            params.filter.media_type = {'in': options.media_types};
        }
        return params;
    };


    _private.getExistingMediaIds = function (fieldSet) {
        let existingIds = [];
        fieldSet.find('input[name*="[object_id]"]').each(function (i, element) {
            existingIds.push($(element).val());
        });
        return existingIds;
    };

    this.showMediaEditForm = function (options, dialog, saveCallBack, deleteCallBack) {
        _private.options = options;
        _private.setUsedMediaIds();
        if (!_.isObject(dialog)) {
            dialog = {};
        }
        if (!_.isFunction(saveCallBack)) {
            saveCallBack = function (res) {
                _private.initUploadForm('#media-upload form');
                if (!_.isUndefined(res.status) && res.status === 'save_ok') {
                    if (!_.isUndefined(options.item)) {
                        _private.updateMediaItem(res.media, options.item);
                        $(options.item).parents('form').trigger('mediaUploadToolChange', [res, options]);
                    }
                    setTimeout(function () {
                        new EfbHelperDialog().dialogDestroy();
                    }, 700)
                }
            };
        }
        let dialogDefault = {
            dialogClass:  'upload-tool',
            closeOutside: false,
            modal:        true,
            height:       'auto',
            ajax:         {
                url:     options.url,
                success: function (response, dialogContainer) {
                    dialogContainer.html(response);
                    _private.media = new Media();
                    _private.media.setSuccessCallback(saveCallBack);
                    _private.media.setDeleteCallback(deleteCallBack);
                    _private.initUploadForm('#media-upload form');
                    if (!_.isUndefined(options.media_types)) {
                        _private.prepareMediaTypeSelect(options.media_types);
                    }
                    if (!_.isUndefined(options.mime_types)) {
                        _private.prepareMediaAcceptedMimeTypes(options.mime_types);
                    }
                    $('body').trigger('mediaEditFormLoaded', [response, dialogContainer])
                }
            }
        };
        new EfbHelperDialog($.extend(dialogDefault, dialog)).show();
    };

    _private.prepareMediaAcceptedMimeTypes = function (mimeTypes) {
        if (mimeTypes.length === 0) {
            return;
        }
        let form  = $('#media-upload').find('form');
        let input = form.find('input[type=file]');
        input.attr('accept', mimeTypes.join(', '));
    };

    _private.prepareMediaTypeSelect = function (mediaTypes) {
        if (mediaTypes.length === 0) {
            return;
        }
        let form   = $('#media-upload').find('form');
        let select = form.find('select[name=media_type]');
        select.find('option').each(function (i, option) {
            let optionVal = $(option).attr('value');
            if ($.inArray(optionVal, mediaTypes) === -1) {
                $(option).remove();
            }
        });
    };

    _private.updateMediaItem = function (media, selector) {
        let mediaItem     = $(selector);
        let mediaEditList = $(mediaItem).parent('div.media-edit-list');
        if (mediaItem.hasClass('media-0') && mediaItem.object_id !== 0) {
            if (mediaEditList.find('input[name*=object_id][value=' + media.object_id + ']').length) {
                mediaItem = mediaEditList.find('input[name*=object_id][value=' + media.object_id + ']')
                                         .parents('div.media-edit-item');
            } else {
                let newEmptyItem = mediaItem.clone(true);
                mediaEditList.append(newEmptyItem);
                mediaItem.removeClass('media-0').addClass('media-' + media.object_id);
            }
        }
        mediaItem.find('input[name*=object_id]').val(media.object_id).attr('disabled', false);
        mediaItem.find('span.title').text(media.title);
        mediaItem.find('span.media-type').text(media['media_type_human']);
        mediaItem.find('span.file-size').text(media['file_size_human']);
        mediaItem.find('a.media-item-edit').attr('href', media['edit_url']);
        mediaItem.find('a.media-item-show').attr('href', media['show_url']);
        new FormObserver(mediaItem.parents('form')).addElement(mediaItem.find('input[name*=object_id]'));
        _private.reorderList(mediaEditList);
    };

    _private.initUploadForm = function (form) {
        form = $(form);
        _private.media.init(form, true);
    };

    _private.setUsedMediaIds = function () {
        let usedObjectIds = [];
        $(_private.options.item).parent('div.media-edit-list').find('input[name*=object_id]').each(
            function (i, input) {
                let objectId = $(input).pVal();
                if (objectId > 0) {
                    usedObjectIds.push(objectId);
                }
            }
        );
        _private.options.usedObjectIds = usedObjectIds;
    };

    _private.reorderList = function (mediaList) {
        $(mediaList).find('div.media-edit-item').each(function (i, line) {
            $(line).find('input').each(function (l, inp) {
                let id   = $(inp).attr('id');
                let name = $(inp).attr('name');
                if (!_.isUndefined(id)) {
                    $(inp).attr('id', 'media-' + i + '-object_id');
                }
                if (!_.isUndefined(name)) {
                    $(inp).attr('name', name.replace(/\d+/, i));
                }
            });
        });
        let wrap = $(mediaList).parent();
        wrap.toggleClass('empty', $(mediaList).find('div.media-edit-item').length <= 1)
    };

});
